<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="filter_by">{{$t('profit_loss_report.period')}}</label>
                                <select name="" id="filter_by" v-model="filters.filter_by" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in period_list" :value="row.slug" :key="'period'+index">{{ row.title }}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('profit_loss_report.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('profit_loss_report.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <b-tabs content-class="mt-3">

                    <b-tab :title="$t('details')" active>
                        <div class="mt-3">
                            <div class="row mb-10">
                                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                                    <h4 class="text-center">{{ $t('profit_loss_report.profit_loss_report')}}  ({{currency_name}})</h4>
                                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                </div>
                                <div class="col-md-12 mt-10" v-if="data_line">
                                    <LineChart :data-line="data_line" :currency-name="currency_name"/>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-12" v-if="data_report_details">
                                    <div class="table-responsive">
                                        <div class="row justify-content-end p-4">
                                            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                                <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                                </button>

                                                <button class="dropdown-item" @click="printData('summaryTableDetails')">
                                                    <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                </button>
                                            </b-dropdown>
                                        </div>
                                        <table class="table table-bordered" id="summaryTableDetails">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <td v-for="(row, index) in data_report_details" :key="row+''+index">
                                                    {{row.label}}
                                                </td>
                                                <td>{{this.$t('profit_loss_report.total')}} ({{currency_name}})</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>{{$t('profit_loss_report.income')}}</th>
                                                <td :colspan="data_report_details ? data_report_details.length + 1 : 1"></td>
                                            </tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.sales_invoices')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'a'+getRandom()+index">
                                                    {{row.sales}}
                                                </td>
                                                <td>{{details_total_sum.sum_sales}}</td>
                                            </tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.income_total')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'b'+getRandom()+index">
                                                    {{row.income}}
                                                </td>
                                                <td>{{details_total_sum.sum_income}}</td>
                                            </tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.expenses')}}</th>
                                                <td :colspan="data_report_details ? data_report_details.length + 1 : 1"></td>
                                            </tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.refund_receipts')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'c'+getRandom()+index">
                                                    {{row.refund}}
                                                </td>
                                                <td>{{details_total_sum.sum_refund}}</td>
                                            </tr>

                                            <tr>
                                                <th>{{$t('profit_loss_report.purchase_invoices')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'e'+getRandom()+index">
                                                    {{row.purchase}}
                                                </td>
                                                <td>{{details_total_sum.sum_purchase}}</td>
                                            </tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.expenses_total')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'f'+getRandom()+index">
                                                    {{row.expenses}}
                                                </td>
                                                <td>{{details_total_sum.sum_expenses}}</td>
                                            </tr>
                                            <tr></tr>
                                            <tr>
                                                <th>{{$t('profit_loss_report.profit')}} ({{currency_name}})</th>
                                                <td v-for="(row, index) in data_report_details" :key="'d'+getRandom()+index">
                                                    {{row.profit}}
                                                </td>
                                                <td>{{details_total_sum.sum_profit}}</td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- <b-tab> -->
                    <!-- </b-tab> -->
                </b-tabs>
<!--                <div class="text-center">-->
<!--                    <button class="btn btn-warning" @click="loadMore">-->
<!--                        <p class="mb-0">-->
<!--                            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>-->
<!--                            {{$t('load_more')}}-->
<!--                        </p>-->
<!--                    </button>-->
<!--                </div>-->

            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";
    import LineChart from './LineChart.vue'

    export default {
        name: "index-profit-loss-report",
        components:{LineChart},
        data() {
            return {
                mainRoute: '/reports/accounting/profit_loss_report',
                mainRouteDependency: 'base/dependency',


                currency_name: null,
                period_list: [
                    {title: this.$t('profit_loss_report.monthly'), slug: 'monthly'},
                    {title: this.$t('profit_loss_report.yearly'), slug: 'yearly'},
                    {title: this.$t('profit_loss_report.quarterly'), slug: 'quarterly'},
                ],

                data_line: [],
                data_report_details: [],
                details_total_sum: {},

                filters: {
                    filter_by: null,
                    from_date: null,
                    to_date: null,
                },

                page: 0,
            }
        },
        computed: {

        },
        beforeMount() {
            this.getAuthUser();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.profit_loss_report")}]);
        },
        methods: {

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
            doFilter() {
                this.getReportDetails();
                this.getReportDetailsForChart();

            },
            resetFilter() {
                this.filters.filter_by = null;
                this.filters.from_date = null;
                this.filters.to_date = null;

            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },

            // loadMore() {
            //     this.page = this.page + 1;
            //     this.getReportDetails();
            //
            // },

            updateValue(value) {
                this.filters.account_id = value
            },

            loadOptions() {
            },
            /*****
             * details
             */

            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters, data_type: 'details'}}).then((response) => {
                    this.data_report_details = response.data.data;
                    this.details_total_sum = response.data.total;
                }).then().catch((error) => {this.$errorAlert(error)});
            },
            getReportDetailsForChart() {
                ApiService.get(this.mainRoute, {params: {...this.filters, data_type: 'chart'}}).then((response) => {
                    this.data_line = response.data;
                });
            },
            convertTableToExcelReport(tableId) {
                this.setReportLog('excel', 'profit and loss report');
                TableToExcel.convert(document.getElementById(tableId), {
                    name: this.$t("MENU.profit_loss_report_reports")+'.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },
            printData(tableId) {
                this.setReportLog('pdf', 'profit and loss report');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
